import APP_LOGO from "../assets/logo.png";

export const APP_NAME = "ODUNA";
export const LOGO = APP_LOGO;
export const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const BACKEND_API_X_KEY = process.env.REACT_APP_APIKEY;
export const USER_TYPE = {
  admin: "ADMIN",
  member: "MEMBER",
};
export const PAYMENT_TYPE = {
  DONATION: "DONATION",
  MEMBERSHIP_FEE: "MEMBERSHIP_FEE",
  MEMBERSHIP_PAYMENT: "MEMBERSHIP_PAYMENTS",
};
