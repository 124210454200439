import React from "react";
import logo from "../assets/logo.png";
import { useRecoilValue } from "recoil";
import authAtom from "../atoms/authAtom";
import { Link, useLocation } from "react-router-dom";
import protectedRoutes from "../router/protected";
import logoutIcon from "../assets/sidebar/logoutIcon.svg";
import useLogout from "../hooks/useLogout";

const SideBar = ({ visible, setVisiblity }) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const {user} = useRecoilValue(authAtom);
  const logout = useLogout();

  const notAllowed = ["Donations", "Membership Fee", "Expense"]
  const allowedRoutes = (user?.user_type === "SUPER_ADMIN" || user?.profession === "Super Admin") ? protectedRoutes : protectedRoutes.filter((routes) => !notAllowed.includes(routes?.title));

  return (
    <nav
      className={`bg-primary h-screen flex flex-col overflow-auto absolute md:static ${
        visible || "hidden"
      } md:block  w-full  max-w-[120px] lg:max-w-xs z-10 rounded-r-[35px]`}
    >
      <img
        src={logo}
        alt="Logo"
        width={112}
        height={104}
        className="mx-auto mt-8"
      />
      <div className="px-7 mt-10 ">
        {allowedRoutes.map(
          ({ title, icon, path, showInSidebar = true }, index) => {
            return (
              showInSidebar && (
                <Link key={title} to={`/admin/${path}`}>
                  <button
                    className={`flex py-4 w-full pl-4 rounded-lg sidebarFont font-medium ${
                      currentUrl === "/admin/" + path && "border font-black"
                    } border-solid border-secondary `}
                    onClick={() => setVisiblity(false)}
                  >
                    <img
                      src={icon}
                      alt={title}
                      className="mr-4"
                      width={24}
                      height={24}
                    />{" "}
                    {title}
                  </button>
                </Link>
              )
            );
          }
        )}
        <button
          onClick={logout}
          className={`flex py-4 w-full pl-4 rounded-lg border-solid border-secondary sidebarFont font-medium`}
        >
          <img
            src={logoutIcon}
            alt="Logout"
            className="mr-4"
            width={24}
            height={24}
          />{" "}
          Logout
        </button>
      </div>
    </nav>
  );
};

export default SideBar;
