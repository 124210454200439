import { useSetRecoilState } from "recoil";
import authAtom from "../atoms/authAtom";
import { toast } from "react-toastify";

const useLogout = (error_message) => {
  const setAuthState = useSetRecoilState(authAtom);

  return () => {
    toast.error(error_message ?? "You have been logged out successfully")
    setAuthState({
      isLoggedIn: false,
      user: null,
      token: "",
    });
    localStorage.clear();
    sessionStorage.clear();
  };
};

export default useLogout;
