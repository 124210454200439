import React from "react";
import { cn } from "../../utils/tailwindCx";

const BaseModal = ({ title, closeModal, Component, customStyle = {} }) => {
  const {
    customModalBackGroundStyle,
    customMainModalStyle,
    customModalHeaderStyle,
    customModalHeaderTitleStyle,
    customHeaderCancelStyle,
  } = customStyle;
  const ModalBackGroundDefaultStyle =
    "bg-dark/20 backdrop-blur-0 z-40 fixed top-0 left-0 w-full flex items-center justify-center h-screen overflow-auto";
  const MainModalDefaultStyle =
    "bg-white rounded-sm md:rounded-md w-full md:w-[40%] p-4 md:p-6";
  const ModalHeaderDefaultStyle =
    "flex items-center justify-between mb-3";
  const ModalHeaderTitleDefaultStyle =
    "txt-dark text-base md:text-lg font-medium";
  const ModalHeaderCancelDefaultStyle =
    "rounded-full border border-dark/50 h-6 w-6 p-2 flex items-center txt-dark justify-center";
  return (
    <div
      className={cn(ModalBackGroundDefaultStyle, customModalBackGroundStyle)}
    >
      <div className={cn(MainModalDefaultStyle, customMainModalStyle)}>
        <div className={cn(ModalHeaderDefaultStyle, customModalHeaderStyle)}>
          <h3
            className={cn(
              ModalHeaderTitleDefaultStyle,
              customModalHeaderTitleStyle
            )}
          >
            {title ?? ""}
          </h3>
          <button
            onClick={closeModal}
            className={cn(
              ModalHeaderCancelDefaultStyle,
              customHeaderCancelStyle
            )}
          >
            X
          </button>
        </div>
        {Component}
      </div>
    </div>
  );
};

export default BaseModal;
