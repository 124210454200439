import { useMemo, createContext } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import authAtom from "../atoms/authAtom";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import useLogout from "../hooks/useLogout";
import { BACKEND_API_URL, BACKEND_API_X_KEY } from "../constants/common";

export const AxiosContext = createContext();
export default function AxiosContextProvider({ children }) {
  const authState = useRecoilValue(authAtom);
  const key = BACKEND_API_X_KEY;
  const logout = useLogout();
  const axiosInstance = useMemo(() => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": key,
    };

    if (authState.isLoggedIn) {
      headers.Authorization = `Bearer ${authState.token}`;
    }
    const onResponse = (response) => {
      return response;
    };

    const onResponseError = async (error) => {
      // const originalRequest = error.config;
      const token = authState?.token;
      if (token) {
        const decoded = jwt_decode(token);
        const isExpired = dayjs.unix(decoded.exp).diff(dayjs()) < 1;
        if (isExpired) logout("Token Has Expired Please Relogin!!!");
      } else if (!token && authState?.isLoggedIn) {
        logout("Token Has Expired Please Relogin!!!");
      }
      // if (error.response) {
      //   if (
      //     (error.response.status === 403 || error.response.status === 401) &&
      //     authState.isLoggedIn &&
      //     isExpired
      //   ) {
      //     // setGetRefreshToken(()e => true);
      //   }
      // }
      return Promise.reject(error);
    };

    const ax = axios.create({
      baseURL: BACKEND_API_URL,
      headers,
    });

    ax.interceptors.response.use(onResponse, onResponseError);
    return ax;
  }, [authState, key, logout]);

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
}
