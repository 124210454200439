import React, { useState } from "react";
import logoutIcon from "../assets/dashboardheader/logoutIcon.svg";
import profilePic from "../assets/dashboardheader/profile_pic.png";
import noImage from "../assets/noImage.png";
import settingsIcon from "../assets/dashboardheader/settingsIcon.svg";
import notificationIcon from "../assets/dashboardheader/notificationIcon.svg";
import { HiMenuAlt3 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useLogout from "../hooks/useLogout";
import headerAtom from "../atoms/headerAtom";
import BaseModal from "./modals/BaseModal";
import authAtom from "../atoms/authAtom";

const UserMobileDropdown = () => {
  const dropdown_items = [
    {
      title: "Dashboard",
      icon: profilePic,
      onclick: () => {},
    },
    {
      title: "Notifications",
      icon: notificationIcon,
      onclick: () => {},
    },
    {
      title: "Settings",
      icon: settingsIcon,
      onclick: () => {},
    },
    {
      title: "Logout",
      icon: logoutIcon,
      onclick: () => {},
    },
  ];

  return (
    <div className="absolute top-12 -left-12 md:hidden">
      <div className="bg-primary-light  rounded-md py-2 w-36">
        {dropdown_items.map(({ title, icon, onclick }) => (
          <div onClick={onclick} className="flex py-3 pl-3 hover:bg-primary">
            <img src={icon} width={18} height={18} alt="" />
            <h6 className="ml-3">{title}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

const DashboardHeader = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const logout = useLogout();
  const headerState = useRecoilValue(headerAtom);
  const authState = useRecoilValue(authAtom);

  const [showDropDown, setshowDropDown] = useState(false);
  const [logoutModal, setlgoutModal] = useState(false);

  return (
    <header className="border-2 h-20 mb-4 p-5 md:p-14 rounded-lg gap-3 shadow-md hover:drop-shadow-xl flex items-center justify-between">
      <div className="flex flex-col">
        <span>Hello {`${authState?.user?.first_name}`}</span>
        <span className="text-sm font-semibold md:font-bold md:text-2xl">
          {headerState?.title ? headerState?.title : ""}
        </span>
      </div>
      <div className="flex flex-row gap-4 relative">
        <img
          onClick={() => navigate("/admin/notifications")}
          className="hidden md:block"
          src={notificationIcon}
          alt=""
        />
        {/* <img
          onClick={() => navigate("/admin/profile")}
          className="hidden md:block"
          src={settingsIcon}
          alt=""
        /> */}

        <img
          className="md:hidden rounded-full"
          src={authState?.user?.image ?? noImage}
          onError={(e) => {
            e.target.src = noImage;
            e.target.onerror = null;
          }}
          onClick={() => setshowDropDown(!showDropDown)}
          alt=""
          width={20}
          height={20}
        />
        <img
          className="hidden md:inline rounded-full"
          src={authState?.user?.image ?? noImage}
          onError={(e) => {
            e.target.src = noImage;
            e.target.onerror = null;
          }}
          onClick={() => navigate("/admin/")}
          alt=""
          width={40}
          height={20}
        />
        {showDropDown && <UserMobileDropdown />}
        <HiMenuAlt3
          onClick={toggleSidebar}
          size={30}
          className="self-center md:hidden"
        />
        <span className="hidden md:block font-bold self-center">
          {authState?.user?.first_name}
        </span>
        <img
          // onClick={() => setlgoutModal(!logoutModal)}
          onClick={() => logout()}
          src={logoutIcon}
          alt=""
          className="hidden md:block max-w-lg"
        />
      </div>
      {logoutModal && (
        // <BaseModal
        // title="Logout"
        // closeModal={() => setlgoutModal(!logoutModal)}
        //   Component={
        //     <div className="w-full p-5" style={{zIndex: 100000}}>
        //       <h1 className="text-xl text-[#010237] font-bold mb-4">Are you sure you want to logout?</h1>
        //       <div className="flex items-center justify-center gap-5">
        //         <button type="button" className="bg-red-500 cursor-pointer text-base md:text-lg text-[#010237] p-4 rounded-md" onClick={logout}>Yes</button>
        //         <button type="button" className="cursor-pointer bg-none border border-gray-300 text-[#010237] text-base md:text-lg p-4 rounded-md" onClick={() => setlgoutModal(!logoutModal)}>No</button>
        //       </div>
        //     </div>
        //   }

        // />
        <div className="bg-dark/20 backdrop-blur-0 z-50 top-0 left-0 w-full flex items-center justify-center h-screen overflow-auto absolute">
          <div
            className="w-full md:w-[50%] bg-white rounded-md p-5 md:p-10 relative"
            style={{ zIndex: 100000 }}
          >
            <button className="absolute top-3 right-3 rounded-full border border-dark/50 h-6 w-6 p-2 flex items-center txt-dark justify-center">
              &times;
            </button>
            <h1 className="text-xl text-center text-[#010237] font-bold mb-4">
              Are you sure you want to logout?
            </h1>
            <div className="flex items-center justify-center gap-5">
              <button
                type="button"
                className="bg-red-500 text-white hover:cursor-pointer text-base md:text-lg p-4 rounded-md"
                onClick={logout}
              >
                Yes
              </button>
              <button
                type="button"
                className="hover:cursor-pointer bg-none border border-gray-300 text-[#010237] text-base md:text-lg p-4 rounded-md"
                onClick={() => setlgoutModal(!logoutModal)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default DashboardHeader;
