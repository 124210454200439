import  React,  { lazy } from "react";

import dashboardIcon from "../assets/sidebar/dashboardIcon.svg";
import profileIcon from "../assets/sidebar/profileIcon.svg";
import manageUserIcon from "../assets/sidebar/manageUserIcon.svg";
import publicationIcon from "../assets/sidebar/publicationIcon.svg";
import byLawIcon from "../assets/sidebar/byLawsIcon.svg";
import mediaIcon from "../assets/sidebar/mediaIcon.svg";
import donationIcon from "../assets/sidebar/donationIcon.svg";
import membershipFeeIcon from "../assets/sidebar/membershipFeeIcon.svg";
import reportIcon from "../assets/sidebar/reportsIcon.svg";

// Protected
const DashBoard = lazy(() => import("../pages/protected/DashBoard"));
const Expense = lazy(() => import("../pages/protected/Expense"));
const Profile = lazy(() => import("../pages/protected/Profile"));
const ManageUsers = lazy(() => import("../pages/protected/ManageUsers"));
const ManageCommittee = lazy(() => import("../pages/protected/Committee"));
const Publications = lazy(() => import("../pages/protected/Publications"));
const EventsList = lazy(() => import("../pages/protected/Events"));
const AddEvents = lazy(() => import("../components/events/AddEvents"));
const AddAwards = lazy(() => import("../components/events/AddAward"));
const CreatePost = lazy(() => import("../pages/protected/CreatePost"));
const ByLaw = lazy(() => import("../pages/protected/ByLaw"));
const CreateLaw = lazy(() => import("../pages/protected/CreateLaw"));
const AddPictures = lazy(() => import("../pages/protected/PicturesUpload"));
const BulkMedia = lazy(() => import("../components/media/MediaBulkUpload"));
const ManageExecutive = lazy(() => import("../pages/protected/ExecutiveUpload"));
const Letter = lazy(() => import("../components/manage_users/Letter"));
const CreateChapter = lazy(() => import("../components/modals/AddChapter"));
const CreateMember = lazy(() => import("../components/manage_users/AddNewMember"));
const Donations = lazy(() => import("../pages/protected/Donations"));
const AddDonatonCat = lazy(() => import("../pages/protected/AddDonationCat"));
const Media = lazy(() => import("../pages/protected/Media"));
const MembershipFee = lazy(() => import("../pages/protected/MembershipFee"));
const Reports = lazy(() => import("../pages/protected/Reports"));

// Unprotected
const Home = lazy(() => import("../pages/static/home"));
const About = lazy(() => import("../pages/static/about"));
const ObaOffa = lazy(() => import("../pages/static/about/obaoffa"));
const Executives = lazy(() => import("../pages/static/about/executives"));
const YouthExecutives = lazy(() => import("../pages/static/about/youth-executives"));
const OffaHistory = lazy(() => import("../pages/static/about/offahistory"));
const OffaCompounds = lazy(() => import("../pages/static/about/offacompounds"));
const Scholarship = lazy(() => import("../pages/static/about/scholarship"));
const ByeLaw = lazy(() => import("../pages/static/about/by-law/ByeLaw"));
const Events = lazy(() => import("../pages/static/about/events"));
const MedicalMission = lazy(() => import("../pages/static/about/medical-mission"));
const SingleMedicalMission = lazy(() => import("../pages/static/about/single-medical-mission"));
const Chapters = lazy(() => import("../pages/static/about/chapters"));
const PastRulers = lazy(() => import("../pages/static/about/pastrulers"));
const Convention = lazy(() => import("../pages/static/about/convention"));
const Awards = lazy(() => import("../pages/static/about/awards"));
const AwardAndScholarship = lazy(() => import("../pages/static/about/award-and-scholarship"));
const Committee = lazy(() => import("../pages/static/about/committee"));
const AllNews = lazy(() => import("../pages/static/news/allnews"));
const SingleNews = lazy(() => import("../pages/static/news/singlenews"));
const Contact = lazy(() => import("../pages/static/contact"));
const MissingPage = lazy(() => import("../pages/MissingPage"));
const MemberRegistration = lazy(() => import("../pages/static/registration/memberregistration"));
const GalleryComponent = lazy(() => import("../pages/static/gallery/gallery"));
const CategoryGalleryComponent = lazy(() => import("../pages/static/gallery/singleCategoryGallery"));
const PaymentComponent = lazy(() => import("../pages/static/payment/index"));
const PaymentDetailsComponent = lazy(() => import("../pages/static/payment/paymentDetails"));
const DonationPage = lazy(() => import("../pages/static/payment/donation"));
const AddExpenses = lazy(() => import("../components/modals/AddExpenses"))
const VerifyPaymentComponent = lazy(() => import("../pages/static/payment/verifyPayment"));
const ManageAdminUsers = lazy(() => import("../pages/protected/ManageAdminUsers"));




//Common
const Login = lazy(() => import("../pages/Login"));
const AccountRecovery = lazy(() => import("../pages/AccountRecovery"));

export type RouteType = {
  displayName: string,
  path: string,
  component: React.ReactElement,
  isProtected: boolean,
  allowedRoles?: string[],
  showInNavbar: boolean,
  showInDashboard?: boolean,
  isCommonRoute?: boolean,
  icon?: string,
};

const unProtectedRoutes = [
  {
    displayName: "Home",
    path: "/",
    component: <Home />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "About Us",
    path: "/about",
    component: <About />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Offa History",
    path: "/about/offahistory",
    component: <OffaHistory />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Offa Compounds",
    path: "/about/offacompounds",
    component: <OffaCompounds />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Oba Offa",
    path: "/about/obaoffa",
    component: <ObaOffa />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Past Rulers",
    path: "/about/pastrulers",
    component: <PastRulers />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "By-Laws",
    path: "/about/by-laws",
    component: <ByeLaw />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Events",
    path: "/about/events",
    component: <Events/>,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Medical Mission",
    path: "/about/medical-mission",
    component: <MedicalMission />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Single Medical Mission",
    path: "/single-medical-mission/:id",
    component: <SingleMedicalMission />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Chapters",
    path: "/about/chapters",
    component: <Chapters/>,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Scholarships",
    path: "/about/scholarship",
    component: <Scholarship/>,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Executives",
    path: "/about/executives",
    component: <Executives />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Youth Executives",
    path: "/about/youth-executives",
    component: <YouthExecutives />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Convention",
    path: "/about/convention",
    component: <Convention />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Awards",
    path: "/about/awards",
    component: <Awards />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Add Donations",
    path: "/admin/add-donation-category",
    component: <AddDonatonCat />,
    isProtected: true,
    showInNavbar: false,
  },
  {
    displayName: "Award & Scholarship",
    path: "/about/award-and-scholarship",
    component: <AwardAndScholarship />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Committee",
    path: "/about/committee",
    component: <Committee />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "News",
    path: "/news",
    component: <AllNews />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Member Registration",
    path: "/memberregistration",
    component: <MemberRegistration />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Single News",
    path: "/single-news/:id",
    component: <SingleNews />,
    isProtected: false,
    showInNavbar: true,
  },
  {
    displayName: "Contact Us",
    path: "/contact",
    component: <Contact />,
    isProtected: false,
    showInNavbar: true,
  },

  {
    title: "login",
    path: "/admin/login",
    component: <Login />,
    isCommonRoute: true,
    isProtected: false
  },
  {
    title: "Account Recovery",
    path: "/admin/account/recovery",
    component: <AccountRecovery />,
    isCommonRoute: true,
    isProtected: false
  },

  {
    title: "Dashboard",
    icon: dashboardIcon,
    path: "/admin",
    component: <DashBoard />,
    isProtected: true
  },
  {
    title: "Dashboard",
    icon: dashboardIcon,
    path: "/admin/dashboard",
    component: <DashBoard />,
    isProtected: true
  },
  {
    title: "Profile Settings",
    icon: profileIcon,
    path: "/admin/profile",
    component: <Profile />,
    isProtected: true
  },
  {
    title: "Manage Users",
    icon: manageUserIcon,
    path: "/admin/manage-users",
    component: <ManageUsers />,
    isProtected: true
  },
  {
    title: "Committee",
    icon: manageUserIcon,
    path: "/admin/committee",
    component: <ManageCommittee />,
    isProtected: true
  },
  {
    title: "Publications",
    icon: publicationIcon,
    path: "/admin/publications",
    component: <Publications />,
    isProtected: true
  },
  {
    title: "Events",
    icon: publicationIcon,
    path: "/admin/events",
    component: <EventsList />,
    isProtected: true
  },
  {
    title: "By-Laws",
    icon: byLawIcon,
    path: "/admin/byLaws",
    component: <ByLaw />,
    isProtected: true
  },
  {
    title: "Gallery",
    icon: byLawIcon,
    path: "/gallery",
    component: <GalleryComponent />,
    isProtected: false
  },
  {
    title: "Single Gallery",
    icon: byLawIcon,
    path: "/category-gallery/:id/:category/:tab",
    component: <CategoryGalleryComponent />,
    isProtected: false
  },
  {
    title: "Payment",
    icon: byLawIcon,
    path: "/payment",
    component: <PaymentComponent />,
    isProtected: false
  },
  {
    title: "Payment Details",
    icon: byLawIcon,
    path: "/paymentdetails",
    component: <PaymentDetailsComponent />,
    isProtected: false
  },
  {
    title: "Donation Payment",
    icon: byLawIcon,
    path: "/donation",
    component: <DonationPage />,
    isProtected: false
  },
  {
    title: "Verify Payment",
    icon: byLawIcon,
    path: "/verifypayments",
    component: <VerifyPaymentComponent />,
    isProtected: false
  },
  {
    title: "Media",
    icon: mediaIcon,
    path: "/admin/media",
    component: <Media />,
    isProtected: true
  },
  {
    title: "Donations",
    icon: donationIcon,
    path: "/admin/donations",
    component: <Donations />,
    isProtected: true
  },
  {
    title: "Membership Fee",
    icon: membershipFeeIcon,
    path: "/admin/membership-fee",
    component: <MembershipFee />,
    isProtected: true
  },
  {
    title: "Report",
    icon: reportIcon,
    path: "/admin/report",
    component: <Reports />,
    isProtected: true
  },
  {
    title: "Create Post",
    path: "/admin/post/create",
    showInSidebar: false,
    component: <CreatePost />,
    isProtected: true
  },
  {
    title: "Create ByLaws",
    path: "/admin/bylaws/create",
    showInSidebar: false,
    component: <CreateLaw />,
    isProtected: true,
  },
  {
    title: "Upload Media",
    path: "/admin/media/upload-media",
    showInSidebar: false,
    component: <AddPictures />,
    isProtected: true,
  },
  {
    title: "Bulk Upload Media",
    path: "/admin/media/bulk-upload-media",
    showInSidebar: false,
    component: <BulkMedia />,
    isProtected: true,
  },
  {
    title: "Add Expense",
    path: "/admin/create-expense",
    showInSidebar: false,
    component: <AddExpenses />,
    isProtected: true,
  },
  {
    title: "Add Award",
    path: "/admin/add-award",
    showInSidebar: false,
    component: <AddAwards />,
    isProtected: true,
  },
  {
    title: "Manage Executives",
    path: "/admin/media/manage-executives",
    showInSidebar: false,
    component: <ManageExecutive />,
    isProtected: true,
  },
  {
    title: "Send Letter",
    path: "/admin/sendletter",
    showInSidebar: false,
    component: <Letter />,
    isProtected: true,
  },
  {
    title: "Add Events",
    path: "/admin/add-event",
    showInSidebar: false,
    component: <AddEvents />,
    isProtected: true,
  },
  {
    title: "Create Chapter",
    path: "/admin/create-chapter",
    showInSidebar: false,
    component: <CreateChapter />,
    isProtected: true,
  },
  {
    title: "Create Member",
    path: "/admin/new-member",
    showInSidebar: false,
    component: <CreateMember />,
    isProtected: true,
  },
  {
    title: "Expense",
    path: "/admin/expense",
    showInSidebar: true,
    component: <Expense />,
    isProtected: true
  },
  {
    title: "Admin Users",
    icon: manageUserIcon,
    path: "/admin/manage-admin-users",
    component: <ManageAdminUsers />,
    isProtected: true,
    showInSidebar: true,
  },
  {
    displayName: "Missing Page",
    path: "*",
    component: <MissingPage />,
    isProtected: false,
    showInNavbar: false,
  },

];

export default unProtectedRoutes;
