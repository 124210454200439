import { Routes, Route, useLocation } from "react-router-dom";
import BaseLayout from "./components/layouts/BaseLayout";
import { Suspense, useMemo } from "react";
import Loading from "./components/common/Loading";
import ProtedtedLayout from "./components/layouts/ProtectedLayout";
import protectedRoutes from "./router/protected";
import commonRoutes from "./router/common";
import unProtectedRoutes from "./router/unprotected";
import UnProtedtedLayout from "./components/layouts/UnProtectedLayout";
import RoutesData from "./router/unprotected";

function App() {
  const { pathname: currentURL } = useLocation();
  const shouldRenderSidebar = useMemo(() => {
    const currentRoute = RoutesData.find((route) => {
      if (route.path.includes(":")) {
        return currentURL.includes(route.path.split(":")[0]);
      }
      return route.path === currentURL;
    });

    return currentRoute?.isProtected? <ProtedtedLayout /> : <UnProtedtedLayout />;
  }, [currentURL]);

  window.addEventListener("scroll", () => {
    const scrollHeight = Math.ceil(window.scrollY)
    // const bodyHeight = document.body.offsetHeight
    const menu = document.getElementById("header-navbar")?.classList
    // if(scrollHeight >= 130 && bodyHeight >= 1200){
    if(scrollHeight >= 130){
      menu?.add("fixed")
      menu?.add("shadow")
    }else {
      menu?.remove("fixed")
      menu?.remove("shadow")
    }
  })

  return (
    <>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route path="" element={shouldRenderSidebar}>
            {unProtectedRoutes?.map(({ path, index, component }) => (
              <Route
                key={path}
                path={path}
                index={index}
                element={
                  <Suspense fallback={<Loading />}>{component}</Suspense>
                }
              />
            ))}
          </Route>
          {/* {commonRoutes?.map(({ path, index, component }) => (
            <Route
              key={path}
              path={path}
              index={index}
              element={<Suspense fallback={<Loading />}>{component}</Suspense>}
            />
          ))} */}
          {/* <Route path="/admin/" element={<ProtedtedLayout />}>
            {protectedRoutes?.map(({ path, index, component }) => (
              <Route
                key={path}
                path={path}
                index={index}
                element={
                  <Suspense fallback={<Loading />}>{component}</Suspense>
                }
              />
            ))}
          </Route> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
