import React from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowUp, IoMdMail } from 'react-icons/io'
import { BsSendFill, BsTelephoneFill } from 'react-icons/bs'
import logo from '../../../assets/images/oduna-logo-bw.png'
import facebook from '../../../assets/icon/facebook-dark.png'
import instagram from '../../../assets/icon/instagram-dark.png'

function FooterComponent() {
  const links = [
    {
        label: "Home",
        path: "/",
    },
    { 
        label: "Chapter",
        path: "/about/chapters",
    },
    {
        label: "History of Offa",
        path: "/about/offahistory",
    },
    {
        label: "Scholarships",
        path: "/about/scholarship",
    },
 
  ]

  const contacts = [
    {
        icon: <BsSendFill className='text-xl' />,
        label: "Visit the office",
        value: "11538 Garland Road, Dallas, Texas. 75218",
    },
    // {
    //     icon: <BsTelephoneFill className='text-xl' />,
    //     label: "Phone",
    //     value: "+1 469-716-7567",
    // },
    {
        icon: <IoMdMail className='text-xl' />,
        label: "Email",
        value: "info@oduna.org",
    },
  ]
 const latestNewsLinks = [
 {label:"Nigeria Embassy in USA", path:"https://nigeriahouse.com/"},
 {label:"Kwara State Gov. Nigeria",path:"https://kwarastate.gov.ng"},
 {label:"Sun News", path:"https://sunnewsonline.com"},
 {label:"OnlinePunch News",path:"https://punchng.com/"},
 {label:"Nigerian Guardian", path:"https://guardian.ng/"},
 {label:"Vanguard Nigeria",path:"https://www.vanguardngr.com/"},
 {label:"Thisday News", path:"https://www.thisdaylive.com/"},
 {label:"Daily Times Nigeria", path:"https://dailytimesng.com/"}]

  return (
    <div className='w-full bg-[#F1F1F1] p-5 md:p-10 !pb-5 text-[#3C5139] space-y-5'>
        <div className='w-full lg:px-20 gap-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5'>
            <div className='md:col-span-2 space-y-5'>
                <img alt='logo' src={logo} />
                <p className=''>ODUNA is a non-political, philanthropic, socio-cultural organization. The organization seeks to assist new African immigrants in adjusting to life in the United States. It will promote African culture and values. Specific activities to be embarked upon include providing assistance to new immigrants.</p>
            </div>
            <div className='md:pt-10 space-y-5'>
                <p className='text-2xl font-bold'>Quick Links</p>
                <div className='space-y-2'>
                    {links?.map(({ label, path }) => (
                        <Link className='block hover:text-[#FDBB2C]' key={path} to={path}>{label}</Link>
                    ))}
                </div>
            </div>
            <div className='md:pt-10 space-y-5'>
                <p className='text-2xl font-bold'>Latest News</p>
                <div className=''>
                    {latestNewsLinks?.map((d) => (
                        <p className='hover:text-[#FDBB2C]'>
                            <a href={d.path} className='decoration-none'>{d.label}</a>
                        </p>
                    ))}
                </div>
            </div>
            <div className='md:pt-10 space-y-5'>
                <p className='text-2xl font-bold'>Contact Us</p>
                <div className='space-y-2 font-bold text-sm'>
                    {contacts?.map(({ icon, label, value }) => (
                        <div key={label} className='flex space-x-5 items-center'>
                            {icon}
                            <div>
                                <p>{label}</p>
                                <p>{value}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <hr />
        <div className='w-full lg:px-20 md:flex justify-between items-center gap-5'>
            <p className='text-xl font-bold'>{`© ${new Date().getFullYear()} - ODUNA All Right Reserved`}</p>
            <div className='flex justify-between items-center md:space-x-20'>
                <div className='flex space-x-3'>
                    <a className='block' href='https://web.facebook.com/odunorthamerica' target='_blank' rel='noreferrer'>
                        <img alt='facebook' src={facebook} />
                    </a>
                    <a className='block' href='https://instagram.com/odunorthamerica' target='_blank' rel='noreferrer'>
                        <img alt='instagram' src={instagram} />
                    </a>
                </div>
                <button className='bg-[#3C5139] py-2 px-3 rounded-lg text-white' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <IoIosArrowUp className='text-2xl' />
                </button>
            </div>
        </div>
    </div>
  )
}

export default FooterComponent