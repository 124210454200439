import React, {useMemo} from "react";
import { Outlet, useLocation } from "react-router-dom";
import  Footer from "./../common/footer/footer.component"
import  Header from "./../common/header/header.component"
import RoutesData from "../../router/unprotected";

const UnProtedtedLayout = () => {
  const { pathname: currentURL } = useLocation();
  const shouldRenderSidebar = useMemo(() => {
    const currentRoute = RoutesData.find((route) => {
      if (route.path.includes(":")) {
        return currentURL.includes(route.path.split(":")[0]);
      }
      return route.path === currentURL;
    });

    return currentRoute?.isCommonRoute;
  }, [currentURL]);
  return (
    <div className="ff-jura">
    {!shouldRenderSidebar && (<Header />)}      
      <div>
        <Outlet />
      </div>
    {!shouldRenderSidebar && (<Footer />)}
    </div>
  );
};

export default UnProtedtedLayout;
