import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import logo from "../../../assets/images/oduna-logo.png";
import indicator from "../../../assets/icon/indicator.png";

function HeaderComponent() {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleChange = () => setIsOpen(!isOpen);
  const [aboutPopup, setAboutPopup] = useState(false);
  const [offaPopup, setOffaPopup] = useState(false);
  const [pos, setIdx] = useState(1);
  const navigate = useNavigate();
  const [popdata, setPopdata] = useState<any[]>([]);
  const menus = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "ODUNA",
      path: "/odunna",
    },
    {
      label: "OFFA",
      path: "/offa",
    },
    {
      label: "Contact Us",
      path: "/contact",
    },
  ];

  return (
    <div
      id="header-navbar"
      className="w-full top-0 p-5 md:px-10 lg:px-20 md:flex justify-between items-center bg-white z-50"
    >
      <div className="w-full md:w-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="logo" className="sm:w-auto h-20 sm:h-18" />
        </Link>
        <button
          onClick={handleChange}
          className="md:hidden border p-2 rounded-lg text-[#3C5139]"
        >
          <AiOutlineMenu className="text-3xl" />
        </button>
      </div>

      <div
        className={`${
          isOpen ? "flex" : "hidden md:flex"
        } flex-col md:flex-row gap-5 md:gap-10 md:items-center text-xl font-bold text-[#3C5139] `}
      >
        {menus?.map(({ label, path }, idx) => {
          if (label.includes("ODUNA") || label.includes("OFFA")) {
            return (
              <div key={path} className="h-[20px] relative">
                {label.includes("ODUNA") && (
                  <div className="flex flex-col space-y-2">
                    <button
                      className="text-start"
                      onClick={() => {
                        setAboutPopup(!aboutPopup);
                        setOffaPopup(false);
                        setPopdata([
                          {
                            label: "About Us",
                            path: "/about",
                          },
                          {
                            label: "Executives",
                            path: "/about/executives",
                          },
                          {
                            label: "Youth Executives",
                            path: "/about/youth-executives"
                          },
                          {
                            label: "Committees",
                            path: "/about/committee"
                          },
                          {
                            label: "By-Laws",
                            path: "/about/by-laws",
                          },
                          {
                            label: "Chapters",
                            path: "/about/chapters",
                          },
                          {
                            label: "Awards & Scholarships",
                            path: "/about/award-and-scholarship",
                          },
                          {
                            label: "News",
                            path: "/news",
                          },
                     
                          {
                            label: "Events",
                            path: "/about/events"
                          },
                          {
                            label: "Gallery",
                            path: "/gallery",
                          },
                      
                          // {
                          //   label: "Scholarship",
                          //   path: "/about/scholarship",
                          // },
                          // {
                          //   label: "Medical Mission",
                          //   path: "/about/medical-mission",
                          // },
                          // {
                          //   label: "Convention",
                          //   path: "/about/convention",
                          // },
                        ]);
                        setIdx(idx);
                      }}
                    >
                      {label}
                    </button>
                    {aboutPopup && (
                      <div className="flex justify-center">
                        <svg
                          width={isOpen ? "14" : "20"}
                          height={isOpen ? "22" : "28"}
                          viewBox="0 0 30 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={
                            isOpen
                              ? `-rotate-90 ${offaPopup ? "mt-8" : "mt-0"}`
                              : "rotate-0"
                          }
                        >
                          <path
                            d="M30 31.3301C30 32.3908 29.8145 33.3086 29.4434 34.0837C29.0723 34.8588 28.6328 35.2464 28.125 35.2464H1.875C1.36719 35.2464 0.927734 34.8588 0.556641 34.0837C0.185547 33.3086 0 32.3908 0 31.3301C0 30.2695 0.185547 29.3516 0.556641 28.5765L13.6816 1.16264C14.0527 0.387547 14.4922 0 15 0C15.5078 0 15.9473 0.387547 16.3184 1.16264L29.4434 28.5765C29.8145 29.3516 30 30.2695 30 31.3301Z"
                            fill="#3C5139"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                )}
                {label.includes("OFFA") && (
                  <div className="flex flex-col space-y-2">
                    <button
                      className="text-start"
                      onClick={() => {
                        setOffaPopup(!offaPopup);
                        setAboutPopup(false);
                        setPopdata([
                          {
                            label: "History of Offa",
                            path: "/about/offahistory",
                          },
                          {
                            label: "Offa Compounds",
                            path: "/about/offacompounds",
                          },
                          {
                            label: "Past Rulers of Offa",
                            path: "/about/pastrulers",
                          },
                          {
                            label: "Oba Mufutau Gbadamosi",
                            path: "/about/obaoffa",
                          },
                        ]);
                      }}
                    >
                      {label}
                    </button>
                    {offaPopup && (
                      <div className="flex justify-center">
                        <svg
                          width={isOpen ? "14" : "20"}
                          height={isOpen ? "22" : "28"}
                          viewBox="0 0 30 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={
                            isOpen
                              ? `-rotate-90 ${offaPopup ? "mt-8" : "mt-0"}`
                              : "rotate-0"
                          }
                        >
                          <path
                            d="M30 31.3301C30 32.3908 29.8145 33.3086 29.4434 34.0837C29.0723 34.8588 28.6328 35.2464 28.125 35.2464H1.875C1.36719 35.2464 0.927734 34.8588 0.556641 34.0837C0.185547 33.3086 0 32.3908 0 31.3301C0 30.2695 0.185547 29.3516 0.556641 28.5765L13.6816 1.16264C14.0527 0.387547 14.4922 0 15 0C15.5078 0 15.9473 0.387547 16.3184 1.16264L29.4434 28.5765C29.8145 29.3516 30 30.2695 30 31.3301Z"
                            fill="#3C5139"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                )}

                {(aboutPopup || offaPopup) && pos === idx && (
                  <div
                    className={`mt-5 shadow-md ${
                      isOpen
                        ? "flex-row items-start top-2 left-28 w-max"
                        : `flex-col items-center top-6 ${
                            offaPopup ? "-left-40 w-max" : "-left-48 w-max"
                          }`
                    } flex absolute z-50`}
                  >
                    <div
                      className={`${
                        offaPopup ? "md:grid-cols-4" : "md:grid-cols-5"
                      } items-center bg-white px-4 py-2 rounded-md shadow-md grid  grid-cols-1 gap-3 mt-4`}
                    >
                      {popdata.map((data) => (
                        <button
                          key={data?.label}
                          className="border p-2 rounded-md border-[#92D987] text-sm"
                          onClick={() => {
                            navigate(data?.path);
                            setAboutPopup(false);
                            setOffaPopup(false);
                          }}
                        >
                          {data?.label}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          }
          return (
            <div key={path} className="h-[20px]">
              <Link
                to={path}
                onClick={() => {
                  setAboutPopup(false);
                  setOffaPopup(false);
                }}
              >
                {label}
              </Link>
              {pathname === path && (
                <img
                  // hidden={aboutPopup || offaPopup}
                  alt={label}
                  src={indicator}
                  className="hidden md:block w-[10px] mx-auto"
                />
              )}
            </div>
          );
        })}
        <div className="md:flex items-center gap-2">
          <Link
            className="block py-2 px-5 rounded-lg border border-[#92D987] hover:bg-[#92D987] hover:text-white"
            to="/memberregistration"
            onClick={() => {
              setAboutPopup(false);
              setOffaPopup(false);
            }}
          >
           Member Registration
          </Link>
          <Link
            className="block py-2 px-5 rounded-lg border border-[#92D987] hover:bg-[#92D987] hover:text-white"
            // to="/paymentdetails?type=donation"
            to="/donation"
            onClick={() => {
              setAboutPopup(false);
              setOffaPopup(false);
            }}
          >
            Donate Now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeaderComponent;
