import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "../SideBar";
import DashboardHeader from "../DashboardHeader";
import { useRecoilValue } from "recoil";
import authAtom from "../../atoms/authAtom";
import { toast } from "react-toastify";
import Loading from "../common/Loading";


const ProtedtedLayout = () => {
  const authState = useRecoilValue(authAtom);
  const isLoggedIn = authState?.isLoggedIn;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      toast.error("You are not logged in");
      navigate("/admin/login");
    }
  }, [isLoggedIn, navigate]);

  const [toggleSidebarState, settoggleSidebarState] = useState(false);
  const toggleSidebar = () => settoggleSidebarState(!toggleSidebarState);

  return (
    <div className="flex w-full !font-roboto">
      {!isLoggedIn ? (
        <Loading />
      ) : (
        <>
          <SideBar
            visible={toggleSidebarState}
            setVisiblity={settoggleSidebarState}
          />
          <div className="w-full pt-3 overflow-auto h-screen md:px-12 !font-roboto">
            <DashboardHeader toggleSidebar={toggleSidebar} />
            <div className="relative w-full !font-roboto">
              <Outlet />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProtedtedLayout;
