import dashboardIcon from "../assets/sidebar/dashboardIcon.svg";
import profileIcon from "../assets/sidebar/profileIcon.svg";
import manageUserIcon from "../assets/sidebar/manageUserIcon.svg";
import publicationIcon from "../assets/sidebar/publicationIcon.svg";
import byLawIcon from "../assets/sidebar/byLawsIcon.svg";
import mediaIcon from "../assets/sidebar/mediaIcon.svg";
import expenseIcon from "../assets/sidebar/expenseIcon.svg";
import donationIcon from "../assets/sidebar/donationIcon.svg";
import membershipFeeIcon from "../assets/sidebar/membershipFeeIcon.svg";
import reportIcon from "../assets/sidebar/reportsIcon.svg";
import { lazy } from "react";

const Expense = lazy(() => import("../pages/protected/Expense"));
const DashBoard = lazy(() => import("../pages/protected/DashBoard"));
const Profile = lazy(() => import("../pages/protected/Profile"));
const ManageUsers = lazy(() => import("../pages/protected/ManageUsers"));
const ManageCommittee = lazy(() => import("../pages/protected/Committee"));
const Publications = lazy(() => import("../pages/protected/Publications"));
const EventsList = lazy(() => import("../pages/protected/Events"));
const CreatePost = lazy(() => import("../pages/protected/CreatePost"));
const ByLaw = lazy(() => import("../pages/protected/ByLaw"));
const Donations = lazy(() => import("../pages/protected/Donations"));
const Media = lazy(() => import("../pages/protected/Media"));
const MembershipFee = lazy(() => import("../pages/protected/MembershipFee"));
const Reports = lazy(() => import("../pages/protected/Reports"));
const ManageAdminUsers = lazy(() => import("../pages/protected/ManageAdminUsers"));

const protectedRoutes = [
  {
    title: "Dashboard",
    icon: dashboardIcon,
    path: "dashboard",
    component: <DashBoard />,
  },
  {
    title: "Profile Settings",
    icon: profileIcon,
    path: "profile",
    component: <Profile />,
  },
  {
    title: "Manage Members",
    icon: manageUserIcon,
    path: "manage-users",
    component: <ManageUsers />,
  },
  {
    title: "Committee",
    icon: manageUserIcon,
    path: "committee",
    component: <ManageCommittee />,
  },
  {
    title: "Publications",
    icon: publicationIcon,
    path: "publications",
    component: <Publications />,
  },
  {
    title: "Events",
    icon: publicationIcon,
    path: "events",
    component: <EventsList />,
  },
  {
    title: "By-Laws",
    icon: byLawIcon,
    path: "byLaws",
    component: <ByLaw />,
  },
  {
    title: "Media",
    icon: mediaIcon,
    path: "media",
    component: <Media />,
  },
  {
    title: "Expense",
    path: "expense",
    icon: expenseIcon,
    component: <Expense />,
  },
  {
    title: "Donations",
    icon: donationIcon,
    path: "donations",
    component: <Donations />,
  },
  {
    title: "Membership Fee",
    icon: membershipFeeIcon,
    path: "membership-fee",
    component: <MembershipFee />,
  },
  // {
  //   title: "Downloads",
  //   icon: reportIcon,
  //   path: "report",
  //   component: <Reports />,
  // },
  {
    title: "Admins",
    icon: manageUserIcon,
    path: "manage-admin-users",
    component: <ManageAdminUsers />,
  },
  {
    title: "Create Post",
    path: "post/create",
    showInSidebar: false,
    component: <CreatePost />,
  },
 
];
export default protectedRoutes;
